import React from "react";
import { useMenuContext } from "../context/menuContext";
function Categories() {
  const { categories, currentCategory, changeCategory } = useMenuContext();
  const allCategoryItemClass =
    currentCategory === "all"
      ? "text-sm text-light py-2 px-4 rounded-md bg-first cursor-pointer"
      : "text-sm text-color py-2 px-4 rounded-md bg-main cursor-pointer";

  return (
    <div className="w-full sticky top-0 bg-light p-4 z-20">
      <div className="flex overflow-x-scroll hide-scroll-bar gap-2">
        <div
          onClick={() => changeCategory("all")}
          className={allCategoryItemClass}
        >
          Tümü
        </div>
        {categories.map((item) => {
          const itemClass =
            currentCategory === item.id
              ? "text-sm text-light py-2 px-4 rounded-md bg-first cursor-pointer"
              : "text-sm text-color py-2 px-4 rounded-md bg-main cursor-pointer";
          return (
            <div
              key={item.id}
              onClick={() => changeCategory(item.id)}
              className="snap-start shrink-0"
            >
              <div className={itemClass}>{item.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Categories;
