import React, { useState } from "react";
import ProductDetail from "./ProductDetail";

function ProductItem({ product, imagePath }) {
  const [productPopup, setProductPopup] = useState(false);
  const changePopupShow = (value) => {
    setProductPopup(value);
  };
  return (
    <>
      <ProductDetail
        popupShow={productPopup}
        product={product}
        changePopupShow={changePopupShow}
      />
      <div className="w-full flex flex-col bg-light shadow-box rounded-2xl p-3 overflow-hidden z-10">
        <div className="rounded-lg w-full sm:h-52 h-40 bg-main">
          <img
            onClick={() => changePopupShow(true)}
            className="w-full h-full rounded-lg object-cover cursor-pointer"
            src={
              product.image ||
              require("../assets/img/products/" + product.id + ".png")
            }
            alt="product"
          />
        </div>
        <div className="mt-1 p-1 grid grid-cols-1 gap-1">
          <div className="flex flex-col gap-1">
            <h3 className="font-semibold text-base capitalize text-color">
              {product.title}
            </h3>
            <p className="font-normal text-sm text-color-light line-clamp-2">
              {product.desc}
            </p>
          </div>
          <div className="flex flex-row justify-between gap-2 items-center">
            <div className="font-semibold text-base text-color">
              ₺{product.price}.99
            </div>
            <button
              onClick={() => changePopupShow(true)}
              className="font-normal text-base  px-3 first-color rounded-sm"
            >
              <i className="bi bi-arrow-right cursor-pointer"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductItem;
