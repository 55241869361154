import React from "react";

function ProductDetail({ popupShow, product, changePopupShow }) {
  return (
    <>
      <div
        onClick={() => changePopupShow(false)}
        className={popupShow ? "popup-opacity show" : "popup-opacity"}
      ></div>
      <div className={popupShow ? "popup-content show" : "popup-content"}>
        <div className="relative mb-2 flex flex-row items-center justify-between">
          <i
            onClick={() => changePopupShow(false)}
            className="cursor-pointer bi bi-arrow-left p-2 text-lg text-color"
          ></i>
          <i
            onClick={() => changePopupShow(false)}
            className="cursor-pointer bi bi-x p-2 text-lg text-color"
          ></i>
        </div>
        <div className="flex flex-col gap-4">
          <div className="w-full grid place-items-center overflow-hidden">
            <img
              className="sm:w-1/3 w-56 sm:h-auto h-56 mb-4 object-cover rounded-lg"
              src={
                product.image ||
                require("../assets/img/products/" + product.id + ".png")
              }
              alt="product"
            />
          </div>
          <h2 className="font-medium text-xl text-center text-color">
            {product.title}
          </h2>
          <p className="font-normal text-base text-center max-w-prose mx-auto text-color-light">
            {product.desc}
          </p>
          <h3 className="font-semibold text-4xl first-color text-center">
            ₺{product.price}.99
          </h3>
        </div>
      </div>
    </>
  );
}

export default ProductDetail;
