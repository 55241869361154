import React from "react";
import { useMenuContext } from "../context/menuContext";

function Header() {
  const { changeColor, theme, changeTheme } = useMenuContext();
  return (
    <header className="relative w-full h-14 p-2 mb-2 bg-light z-40">
      <div className="sm:container mx-auto flex fles-wor flex-nowrap gap-2 items-center justify-between">
        <div className="relative w-full flex flex-row flex-nowrap gap-2 items-center justify-start">
          <img
            className="w-10 h-10 rounded-full"
            src="https://marketplace.canva.com/EAESMsqG9rI/3/0/1600w/canva-grey-%26-green-elegant-minimal-good-taste-food-restaurant-logo-jeSR74GRMC8.jpg"
            alt="logo"
          />
          <h1 className="md:text-md text-sm text-color">Restaurant Menu</h1>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <div
            onClick={() => changeTheme()}
            className="w-7 h-7 rounded-full text-xs grid place-items-center cursor-pointer bg-first text-light"
          >
            {theme ? (
              <i className="bi bi-sun"></i>
            ) : (
              <i className="bi bi-moon"></i>
            )}
          </div>
          <label
            htmlFor="color"
            className="bg-first w-7 h-7 text-xs grid place-items-center rounded-full cursor-pointer text-light overflow-hidden"
          >
            <i className="bi bi-brush"></i>
          </label>
          <input
            type="color"
            id="color"
            className="h-0 w-0 p-0 m-0 border-none invisible"
            onChange={(e) => changeColor(e.target.value)}
          />
        </div>
      </div>
    </header>
  );
}

export default Header;
