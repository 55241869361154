import React from "react";
import { useMenuContext } from "../context/menuContext";
import ProductItem from "./ProductItem";
function Products() {
  const { products, currentCategory } = useMenuContext();
  return (
    <div className="w-full px-4 grid sm:grid-cols-3 grid-cols-2 gap-4">
      {products.map((product) => {
        if (
          currentCategory === Number(product.category) ||
          currentCategory === "all"
        ) {
          return <ProductItem key={product.id} product={product} />;
        } else {
          return null;
        }
      })}
    </div>
  );
}

export default Products;
