import Carousel from "./components/Carousel";
import Categories from "./components/Categories";
import Header from "./components/Header";
import Products from "./components/Products";

function App() {
  return (
    <div className="relative min-h-screen max-w-screen-md mx-auto bg-light pb-4">
      <Header />
      <Carousel />
      <Categories />
      <Products />
    </div>
  );
}

export default App;
