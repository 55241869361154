import React, { createContext, useContext, useEffect, useState } from "react";
const MenuContext = createContext();

function Provider({ children }) {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("all");
  const [carousel, setCarousel] = useState([]);
  const [color, setColor] = useState("");
  const [theme, setTheme] = useState(false);

  useEffect(() => {
    getProducts();
    getCategories();
    getCarousel();
    getColor();
  }, []);

  const getProducts = () => {
    const productsData = [
      {
        id: 1,
        category: 1,
        title: "Kahvaltı Tabağı",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 75,
      },
      {
        id: 2,
        category: 1,
        title: "İki kişilik Serpme Kahvaltı",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 199,
      },
      {
        id: 3,
        category: 1,
        title: "Geleneksel Tost",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 45,
      },
      {
        id: 4,
        category: 2,
        title: "Cheese Burger",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 129,
      },
      {
        id: 5,
        category: 2,
        title: "Double Cheese Burger",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 149,
      },
      {
        id: 6,
        category: 2,
        title: "Chicken Burger",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 109,
      },
      {
        id: 7,
        category: 2,
        title: "Mini Hamburger (3'lü)",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 39,
      },
      {
        id: 8,
        category: 3,
        title: "Keçi Peynirli Salata",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 99,
      },
      {
        id: 9,
        category: 3,
        title: "Sezar Salata",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 119,
      },
      {
        id: 10,
        category: 3,
        title: "Roka Bonfile Salata",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 139,
      },
      {
        id: 11,
        category: 4,
        title: "San Sebastian",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 69,
      },
      {
        id: 12,
        category: 4,
        title: "Profiterol",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 69,
      },
      {
        id: 13,
        category: 4,
        title: "Üçgen Dilim Pasta",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 69,
      },
      {
        id: 14,
        category: 4,
        title: "Lluvia",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 69,
      },
      {
        id: 15,
        category: 5,
        title: "Milkshake",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 49,
      },
      {
        id: 16,
        category: 5,
        title: "Sprite",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 19,
      },
      {
        id: 17,
        category: 5,
        title: "Kola",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 39,
      },
      {
        id: 18,
        category: 5,
        title: "Limonata",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 19,
      },
      {
        id: 19,
        category: 6,
        title: "Sıcak Çikolata",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 49,
      },
      {
        id: 20,
        category: 6,
        title: "Sütlü Nescafe",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 19,
      },
      {
        id: 21,
        category: 6,
        title: "Türk Kahvesi",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 49,
      },
      {
        id: 22,
        category: 6,
        title: "Çay",
        desc: "Ürünlerini müşterilerine en kolay ve en hızlı şekilde sunabilmek için QR Menü seninle.",
        price: 9,
      },
    ];
    setProducts(productsData);
  };

  const getCategories = () => {
    const categoriesData = [
      {
        id: 1,
        name: "Kahvaltılar",
      },
      {
        id: 2,
        name: "Burgerler",
      },
      {
        id: 3,
        name: "Salatlar",
      },
      {
        id: 4,
        name: "Tatlılar",
      },
      {
        id: 5,
        name: "Soğuk İçecekler",
      },
      {
        id: 6,
        name: "Sıcak İçecekler",
      },
    ];
    setCategories(categoriesData);
  };

  const getColor = () => {
    const currentColor = getComputedStyle(document.body).getPropertyValue(
      "--first-color"
    );
    setColor(currentColor);
  };

  const changeColor = (value) => {
    setColor(value);
    document.querySelector(":root").style.setProperty("--first-color", value);
  };

  const changeTheme = () => {
    document.body.className = theme ? "" : "dark-mode";
    setTheme(!theme);
  };

  const getCarousel = () => {
    const carouselData = [
      {
        id: 1,
        image: "assets/img/banners/1.png",
      },
      {
        id: 2,
        image: "assets/img/banners/2.png",
      },
      {
        id: 3,
        image: "assets/img/banners/3.png",
      },
      {
        id: 4,
        image: "assets/img/banners/4.png",
      },
    ];
    setCarousel(carouselData);
  };

  const changeCategory = (getId) => setCurrentCategory(getId);

  const sharedValues = {
    products,
    setProducts,
    categories,
    setCategories,
    currentCategory,
    setCurrentCategory,
    changeCategory,
    carousel,
    color,
    changeColor,
    theme,
    changeTheme,
  };

  return (
    <MenuContext.Provider value={sharedValues}>{children}</MenuContext.Provider>
  );
}

const useMenuContext = () => useContext(MenuContext);
export { Provider, useMenuContext };
export default MenuContext;
